import React from "react"
import Barcode from "react-barcode"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import SaveIcon from "@material-ui/icons/Save"

import { toBlob } from "html-to-image"
import { saveAs } from "file-saver"

class BarcodeWrapper extends React.Component<{
  value: string
  placeHolderText?: string
}> {
  render() {
    if (!this.props.value || this.props.value.length == 0) {
      return (
        <TextField
          disabled
          fullWidth
          multiline
          rows={7}
          value={this.props.placeHolderText}
        ></TextField>
      )
    }

    return (
      <Box>
        <Box id="barcode" component="div" overflow="auto">
          <Barcode renderer="canvas" value={this.props.value}></Barcode>
        </Box>
        <Box
          component="span"
          mt={1}
          width={1}
          mx="auto"
          display="flex"
          justifyContent="center"
        >
          <Button
            disabled={!this.props.value || this.props.value.length === 0}
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => {
              toBlob(document.getElementById("barcode").firstChild).then(
                blob => {
                  saveAs(blob, "barcode.png")
                }
              )
            }}
          >
            Save image
          </Button>
        </Box>
      </Box>
    )
  }
}

export default BarcodeWrapper
