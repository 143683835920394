import React from "react"
import { graphql } from "gatsby"
import ToolLayout from "../../../components/tool_layout"
import BarcodeWrapper from "../../../components/wrappers/barcode"
import PipeWidget from "../../../components/widgets/pipe"
import { validate_url } from "../../../components/helpers/validator"

export const query = graphql`
  query UrlBarcodeQuery {
    allToolsJson(filter: { path: { eq: "data/barcode" } }) {
      edges {
        node {
          path
          title
          description
          links {
            mdn
            github
          }
        }
      }
    }
  }
`

class BarcodePageState {
  text: string = ""
}

class BarcodePage extends React.Component<{ data: any }> {
  state: BarcodePageState

  constructor(props) {
    super(props)
    this.state = new BarcodePageState()
  }

  render() {
    return (
      <ToolLayout data={this.props.data}>
        <PipeWidget
          outputComponent={BarcodeWrapper}
          exceptionHandler={e => {
            if (e) {
              return "Invalid input"
            }
          }}
          inputProps={{
            placeHolderText: "Input text here",
          }}
          outputProps={{
            placeHolderText: "Barcode will be shown here",
          }}
          valueHook={text => {
            this.setState({
              text: text,
            })
          }}
        />
      </ToolLayout>
    )
  }
}

export default BarcodePage
